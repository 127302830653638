const POST_ADD_CONTACT = 'POST_ADD_CONTACT';
const POST_ADD_CONTACT_RECEIVED = 'POST_ADD_CONTACT_RECEIVED';
const DELETE_CONTACT = 'DELETE_CONTACT';
const DELETE_CONTACT_RECEIVED = 'DELETE_CONTACT_RECEIVED';
const REMOVE_CONTACT = 'REMOVE_CONTACT';
const GET_CONTACTS = 'GET_CONTACTS';
const GET_CONTACTS_RECEIVED = 'GET_CONTACTS_RECEIVED';
const RESET_ADD_CONTACT = 'RESET_ADD_CONTACT';
const RESET_GET_CONTACTS = 'RESET_GET_CONTACTS';

export {
	POST_ADD_CONTACT,
	POST_ADD_CONTACT_RECEIVED,
	RESET_ADD_CONTACT,
	GET_CONTACTS,
	GET_CONTACTS_RECEIVED,
	RESET_GET_CONTACTS,
	DELETE_CONTACT,
	DELETE_CONTACT_RECEIVED,
	REMOVE_CONTACT
};
