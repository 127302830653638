const START_CHANNEL = 'START_CHANNEL';
const STOP_CHANNEL = 'STOP_CHANNEL';
const SERVER_ON = 'SERVER_ON';
const SERVER_OFF = 'SERVER_OFF';

export {
	START_CHANNEL,
	STOP_CHANNEL,
	SERVER_ON,
	SERVER_OFF
};
