const POST_SIGNUP = 'POST_SIGNUP';
const POST_SIGNUP_RECEIVED = 'POST_SIGNUP_RECEIVED';
const POST_SIGNIN = 'POST_SIGNIN';

const POST_SIGNIN_GOOGLE = 'POST_GOOGLE_SIGNIN';
const POST_SIGNUP_GOOGLE = 'POST_GOOGLE_SINGUP';
const POST_SIGNUP_GOOGLE_RECEIVED = 'POST_SIGNUP_GOOGLE_RECEIVED';

const POST_SIGNIN_RECEIVED = 'POST_SIGNIN_RECEIVED';
const GET_VERIFY_EMAIL = 'GET_VERIFY_EMAIL';
const GET_VERIFY_EMAIL_RECEIVED = 'GET_VERIFY_EMAIL_RECEIVED';
const RESET_VERIFY_EMAIL = 'RESET_VERIFY_EMAIL';
const RESET_SIGNIN = 'RESET_SIGNIN';

export {
  POST_SIGNIN,
  POST_SIGNIN_RECEIVED,
  RESET_SIGNIN,
  POST_SIGNUP,
  POST_SIGNUP_RECEIVED,
  GET_VERIFY_EMAIL,
  GET_VERIFY_EMAIL_RECEIVED,
  RESET_VERIFY_EMAIL,
  POST_SIGNIN_GOOGLE,
  POST_SIGNUP_GOOGLE_RECEIVED,
  POST_SIGNUP_GOOGLE,
};
