const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
const GET_CONVERSATIONS_RECEIVED = 'GET_CONVERSATIONS_RECEIVED';
const SET_CURRENT_CONVERSATION_MESSAGES = 'SET_CURRENT_CONVERSATION_MESSAGES';
const ADD_MESSAGE_TO_CURRENT_CONVERSATION_MESSAGES =
  'ADD_MESSAGE_TO_CURRENT_CONVERSATION_MESSAGES';
const INCREMENT_CONVERSATION_UNREAD_MESSAGES =
  'INCREMENT_CONVERSATION_UNREAD_MESSAGES';
const RESET_CONVERSATION_UNREAD_MESSAGES = 'RESET_CONVERSATION_UNREAD_MESSAGES';
const DELETE_CONVERSATION = 'DELETE_CONVERSATION';
const DELETE_CONVERSATION_RECEIVED = 'DELETE_CONVERSATION_RECEIVED';
const REMOVE_CONVERSATION = 'REMOVE_CONVERSATION';
const REMOVE_MESSAGE_FROM_CONVERSATION = 'REMOVE_MESSAGE_FROM_CONVERSATION';
const VIDEO_CALL_CALLING = 'VIDEO_CALL_CALLING';

export {
  SET_CURRENT_CONVERSATION,
  GET_CONVERSATIONS,
  GET_CONVERSATIONS_RECEIVED,
  SET_CURRENT_CONVERSATION_MESSAGES,
  ADD_MESSAGE_TO_CURRENT_CONVERSATION_MESSAGES,
  INCREMENT_CONVERSATION_UNREAD_MESSAGES,
  RESET_CONVERSATION_UNREAD_MESSAGES,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_RECEIVED,
  REMOVE_CONVERSATION,
  REMOVE_MESSAGE_FROM_CONVERSATION,
  VIDEO_CALL_CALLING,
};
