const START_VIDEO = 'START_VIDE';
const VIDEO_ACCEPTED = 'VIDEO_ACCEPTED';
const VIDEO_CLOSED = 'VIDEO_CLOSED';
const VIDEO_MISSED = 'VIDEO_MISSED';
const VIDEO_CALLING = 'VIDEO_CALLING';
const ACCEPT_VIDEO_CALL = 'ACCEPT_VIDEO_CALL';

export {
  START_VIDEO,
  VIDEO_ACCEPTED,
  VIDEO_MISSED,
  VIDEO_CLOSED,
  VIDEO_CALLING,
  ACCEPT_VIDEO_CALL,
};
