const POST_MESSAGE = 'POST_MESSAGE';
const POST_MESSAGE_RECEIVED = 'POST_MESSAGE_RECEIVED';
const GET_MESSAGES = 'GET_MESSAGES';
const GET_MESSAGES_RECEIVED = 'GET_MESSAGES_RECEIVED';
const DELETE_MESSAGE = 'DELETE_MESSAGE';
const DELETE_MESSAGE_RECEIVED = 'DELETE_MESSAGE_RECEIVED';

export {
	POST_MESSAGE,
	POST_MESSAGE_RECEIVED,
	GET_MESSAGES,
	GET_MESSAGES_RECEIVED,
	DELETE_MESSAGE,
	DELETE_MESSAGE_RECEIVED
};
